import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Item, TypeChampEnum, TypeUserActionEnum, Application, ChampIhm, PrivateRow, PrivateCell, Utilisateur } from '../models';
import { PrivateService } from './'
import { Principal } from '../shared/auth/principal.service'
import { Router } from '@angular/router';

@Injectable()
export class DataService {

  public listTable: Item[] = [
  ];
  public listGroup: Utilisateur[] = [];
  public application: Application;
  public listObjValue: any[] = [];
  public navigationHistory = [];
  private notifyevent = new Subject<any>();
  notifyevent$ = this.notifyevent.asObservable();
  public notifyDataChanges = new Subject<any>();
  notifyDataChanges$ = this.notifyDataChanges.asObservable();
  public notifyDropEnd = new Subject<boolean>();
  notifyDropEnd$ = this.notifyDropEnd.asObservable();
  public currentPropertyWindow: number = null;
  public currentPropertyWindowWidth: number = 0;
  public currentPropertyWindowFlex: number = 0;
  public currentPropertyWindowColor: string = '';
  public currentMenuParent = null;
  public currentMenuId = null;
  public isRightOpen = false;
  public listPopup = [];
  public popAltitude = 2000;
  public listVariable: any = [
    
  ];

  constructor(
    private privateService: PrivateService,
    private principal: Principal,
    private router: Router,
  ) { }

  setObjectVariable(variables, idobject): void {
    this.listVariable = this.listVariable.filter((z) => (z.source !== idobject));
    for (const variable of variables) {
      this.listVariable.push({ id: variable.id, data: variable.data, type: variable.type, origine: 'global', source: idobject, name: '' });
    }
  }

  deleteObjectVariable(id): void {
    this.listVariable = this.listVariable.filter((z) => (z.source != id));
  }

  setPageVariables(variables): void {
    this.listVariable = this.listVariable.filter((z) => (z.source !== 'page'));
    for (const variable of variables) {
      this.listVariable.push({ id: variable.id, data: variable.variablesource, type: variable.typevariable, origine: 'global', source: 'page', name: ''});
    }
  }

  selectNavigation(guid): void {
    const navigation = this.navigationHistory.find((f) => (f.id === guid));
    if (navigation !== undefined) {
      const index = this.navigationHistory.indexOf(navigation);
      this.navigationHistory.splice(index + 1, this.navigationHistory.length - index);
      this.setPageVariables(navigation.variables);
      this.router.navigate(['/'+navigation.refpage]);
    }
  }

  findVariable(id): any {
    const variable = this.listVariable.find((f) => f.id === +id);
    if (undefined !== variable) {
      return variable.data;
    } else {
      return null;
    }
  }

  setVariable(id, data) {
    const variable = this.listVariable.find((f) => (f.id === +id));
    if (variable !== undefined) {
      this.listVariable[this.listVariable.indexOf(variable)].data = data;
    }
  }

  setEnrVariable(id, field, data) {
    const variable = this.listVariable.find((f) => (f.id === +id));
    if (variable !== undefined) {
      this.listVariable[this.listVariable.indexOf(variable)].data.mapUdoFieldModel[field].value = data;
    }
  }

  addToVariable(id, data) {
    const variable = this.listVariable.find((f) => (f.id === +id));
    if (variable !== undefined) {
      this.listVariable[this.listVariable.indexOf(variable)].data.push(data);
    }
  }

  deleteAllFromVariable(id) {
    const variable = this.listVariable.find((f) => (f.id === +id));
    if (variable !== undefined) {
      this.listVariable[this.listVariable.indexOf(variable)].data = [];
    }
  }

  deleteFromVariable(id, index) {
    const variable = this.listVariable.find((f) => (f.id === +id));
    if (variable !== undefined) {
      this.listVariable[this.listVariable.indexOf(variable)].data.splice(index, 1);
    }
  }

  addVariableIfNotExist(vari, source) {
    const variable = this.listVariable.find((f) => (f.id === +vari.id));
    if (variable === undefined) {
      this.listVariable.push({ id: vari.id, data: vari.variablesource, type: vari.typevariable, origine: 'global', source: source, name: ''});
    }
  }

  setVariables(list: any[]): void {
    for (const variable of list) {
      this.listVariable.push(variable);
    }
  }

  public updateEnrVariable(row, value, champ, link) {
    if (row !== null && row !== undefined && row.mapUdoFieldModel !== undefined && row.mapUdoFieldModel['@@source@@'] !== undefined) {
      const id = row.mapUdoFieldModel['@@source@@'].value
      if (id.substring(0,4) === 'vari') {
        const idvariable = id.split('_')[1];
        const variable = this.listVariable.find((f) => (f.id === +idvariable));
        if (variable !== undefined) {
          this.listVariable[this.listVariable.indexOf(variable)].data.mapUdoFieldModel[champ].value = value;
          this.dataChanged(id, this.listVariable[this.listVariable.indexOf(variable)].data);
        }
      }
    } else if (link !== null && link !== undefined && link[0] !== null && link[0] !== undefined && link[0].mapUdoFieldModel !== undefined && link[0].mapUdoFieldModel['@@source@@'] !== undefined) {
      const id = link[0].mapUdoFieldModel['@@source@@'].value
      if (id.substring(0,4) === 'vari') {
        const idvariable = id.split('_')[1];
        const variable = this.listVariable.find((f) => (f.id === +idvariable));
        if (variable !== undefined) {
          this.listVariable[this.listVariable.indexOf(variable)].data[link[1]].mapUdoFieldModel[champ].value = value;
          this.dataChanged(id, this.listVariable[this.listVariable.indexOf(variable)].data);
        }
      }
    }
  }

  deleteVariables(id): void {
    this.listVariable = this.listVariable.filter((z) => (z.source != id));
  }

  public newevent(event: any): void {
    this.notifyevent.next(event);
  }

  public updateMultiObjValue(idChampIhm, listTypeValeur): void {
    for (const data of listTypeValeur) {
      this.updateObjValue(idChampIhm, data.value, data.type);
    }
  }

  public updateObjValue(idChampIhm, value, typevaleur= 0, index= ''): void {
    const idd = `${idChampIhm}_${typevaleur}_${index}`;
    const obj = this.listObjValue.find((f) => f.id === idd);
    if (obj !== undefined) {
      this.listObjValue[this.listObjValue.indexOf(obj)].value = value;
    } else {
      this.listObjValue.push({ id: idd, idObj: idChampIhm, value, index});
    }
    // this.variableChanged(`obj_${typevaleur}_${idChampIhm}`, value);
  }

  public deleteObjValue(idObj, index= ''): void {
    this.listObjValue = this.listObjValue.filter((f) => (f.idObj !== idObj));
  }

  public findObjValue(idChampIhm, typevaleur, index= ''): any {
    const idd = `${idChampIhm}_${typevaleur}_${index}`;
    const obj = this.listObjValue.find((f) => f.id === idd);
    if (obj !== undefined) {
      return obj.value;
    } else {
      return null;
    }
  }

  setObjetValue(value, reference, row = undefined, datarow = undefined): void {
    const privateRow: PrivateRow = new PrivateRow();
    if (row !== null && row !== undefined && row[0] !== undefined) {
      this.updateEnrVariable(datarow, value, reference, row);
      if (row[0].mapUdoFieldModel.uf_3 === undefined) { return; }
      privateRow.id = row[0].mapUdoFieldModel.uf_3.value;
      privateRow.item = this.getTable(this.getRowInfo(row[0], 'iditem'));
    } else if (datarow !== null && datarow !== undefined) {
      this.updateEnrVariable(datarow, value, reference, row);
      if (datarow.mapUdoFieldModel.uf_3 === undefined) { return; }
      privateRow.id = datarow.mapUdoFieldModel.uf_3.value;
      privateRow.item = this.getTable(this.getRowInfo(datarow, 'iditem'));
    }
    const cell: PrivateCell = new PrivateCell();
    cell.champref = reference;
    cell.value = value;
    privateRow.listPrivateCell.push(cell);
    this.privateService.addPrivateRow(privateRow).subscribe();
  }

  setMultiObjetValue(listCell: PrivateCell[], row = undefined, datarow = undefined): void {
    const privateRow: PrivateRow = new PrivateRow();
    if (row !== null && row !== undefined && row[0] !== undefined) {
      for (const cell of listCell) {
        this.updateEnrVariable(datarow, cell.value, cell.champref, row);
      }
      if (row[0].mapUdoFieldModel.uf_3 === undefined) { return; }
      privateRow.id = row[0].mapUdoFieldModel.uf_3.value;
      privateRow.item = this.getTable(this.getRowInfo(row[0], 'iditem'));
    } else if (datarow !== null && datarow !== undefined) {
      for (const cell of listCell) {
        this.updateEnrVariable(datarow, cell.value, cell.champref, row);
      }
      if (datarow.mapUdoFieldModel.uf_3 === undefined) { return; }
      privateRow.id = datarow.mapUdoFieldModel.uf_3.value;
      privateRow.item = this.getTable(this.getRowInfo(datarow, 'iditem'));
    }
    privateRow.listPrivateCell = listCell;
    this.privateService.addPrivateRow(privateRow).subscribe();
  }

  public updRow(row, type, _table, id): void {
    if (row !== undefined && !_table.isquery && _table.id !== +this.getRowInfo(row, 'iditem') && row.mapUdoFieldModel.uf_3 !== undefined) {
      const privateRow: PrivateRow = new PrivateRow();
      privateRow.id = row.mapUdoFieldModel.uf_3.value;
      privateRow.item = this.getTable(+this.getRowInfo(row, 'iditem'));
      this.privateService.getPrivateItemRow(privateRow).subscribe((res: any) => {
        this.updateObjValue(id, res, type);
        this.dataChanged(`obj_${type}_${id}`, res);
      });
    } else {
      this.updateObjValue(id, (row !== undefined ? row : null), type);
      this.dataChanged(`obj_${type}_${id}`, (row !== undefined ? row : null));
    }
  }

  public dataChanged(id, value): void {
    this.notifyDataChanges.next({ id, value });
  }

  public getTable(id): Item {
    return this.listTable.find((f) => f.id === +id);
  }

  public getStandardRow(item: Item): any {
    let fields = '';
    for (const field of item.listChampNatif) {
      if (field.reference.substr(0, 2) !== 'uf' || field.reference === 'uf_31') {
        if (fields !== '') {
          fields += ', '
        }
        fields += `"${field.reference}": { "type": null, "value": ""}`
      }
    }
    let row = `{"mapUdoFieldModel": {${fields}}}`
    return JSON.parse(row);
  }

  getObjetValue(reference, row = undefined, datarow = undefined): any {
    if (row !== null && row !== undefined && row[0] !== undefined && row[0].mapUdoFieldModel[reference] !== undefined) {
      return row[0].mapUdoFieldModel[reference].value;
    } else if (datarow !== null
     && datarow !== undefined
     && datarow.mapUdoFieldModel !== undefined
     && datarow.mapUdoFieldModel[reference] !== undefined) {
      return datarow.mapUdoFieldModel[reference].value;
    }
    return null;
  }

  public tableMapping(_table: Item, mapping, listParametre = [], listVariable = []): Item {
    if (!_table) {
      return;
    } else {
      if (!_table.isquery) { return _table; }
      for (const userQuery of _table.listUserQuery) {
        for (const champ of userQuery.listChampIhm) {
          if (champ.type === TypeChampEnum.FILTRE) {
            const refSource = this.getOption(champ.listChampIhmOptions, 'ReferenceChamp').toString();
            for (const map of mapping) {
              if (map.ref === refSource && map.value !== null && map.value !== undefined) {
                this.setOption(champ, 'ValeurFiltre', map.value);
              }
            }
          }
        }
      }
      return _table;
    }
  }

  addPopup(type, data= null, parent= null): void {
    this.listPopup.push({ type, id: this.guid(), altitude: this.getPopAltitude(), data: JSON.parse(JSON.stringify(data))});
  }

  getPopAltitude(): any {
    let max = this.popAltitude;
    for (const pop of this.listPopup) {
      if (pop.altitude > max) {
        max = pop.altitude;
      }
    }
    max = max + 1;
    return max;
  }

  removePopup(id): void {
    const pop = this.listPopup.find((f) => f.id === id);
    if (undefined !== pop) {
      this.listPopup = this.listPopup.filter((f) => (f.id !== id));
    }
  }

  setMenu(id, idpere): void {
    this.currentMenuParent = idpere;
    this.currentMenuId = id;
  }

  showPropertyWindow(id, width, flex, color): void {
    if (this.isRightOpen === true) {
      if (this.currentPropertyWindow !== null && this.currentPropertyWindow !== id) {
        this.currentPropertyWindow = null;
        this.currentPropertyWindowWidth = 0;
        this.currentPropertyWindowFlex = flex;
        this.currentPropertyWindowColor = color;
        this.isRightOpen = false;
        setTimeout(() => {
          this.currentPropertyWindow = id;
          this.currentPropertyWindowWidth = width;
          this.currentPropertyWindowFlex = flex;
        this.currentPropertyWindowColor = color;
          this.isRightOpen = true;
        }, 250);
      }
    } else {
      this.currentPropertyWindow = id;
      this.currentPropertyWindowWidth = width;
      this.currentPropertyWindowFlex = flex;
      this.currentPropertyWindowColor = color;
      this.isRightOpen = true;
    }
  }

  removePropertyWindow(): void {
    this.deleteObjectVariable(this.currentPropertyWindow);
    this.currentPropertyWindow = null;
    this.currentPropertyWindowWidth = 0;
    this.currentPropertyWindowFlex = 0;
    this.currentPropertyWindowColor = '';
    this.isRightOpen = false;
  }

  public getRowInfo(row, info): string {
    const object = JSON.parse(row.mapUdoFieldModel.uf_12.value);
    let value = '';
    if (info === 'idtable') {
      value = object[object.length - 1];
      return value[0];
    } else if (info === 'reftable') {
      value = object[object.length - 1];
      return value[1];
    } else if (info === 'iditem') {
      value = object[object.length - 1];
      return value[2];
    } else if (info === 'idtableapp') {
      value = object[0];
      return value[0];
    } else if (info === 'refapp') {
      value = object[0];
      return value[1];
    } else if (info === 'idapp') {
      value = object[0];
      return value[2];
    }
  }

  public dragEnd() {
    this.notifyDropEnd.next(true);
  }

  public isVisible(visibility, typeright, rights, attribute = null) {
    const currentUser: Utilisateur = this.principal.getCurrentUser();
    if (typeright === 1) {
      let hasRight = false;
      for (const droit of rights) {
        if (droit.iduser === currentUser.id || this.listGroup.find((f) => f.id === droit.iduser) !== undefined) {
          hasRight = true;
          break;
        }
      }
      if (hasRight) {
        if (attribute === null) {
          return visibility;
        } else {
          return attribute;
        }
      } else {
        return false;
      }
    } else {
      if (attribute === null) {
        return visibility;
      } else {
        return attribute;
      }
    }
  }

  isAlterable(ensaisie, typeright): boolean {
    const currentUser: Utilisateur = this.principal.getCurrentUser();
    if (typeright === 1) {
      let hasRight = false;
      return hasRight;
    } else {
      return ensaisie;
    }
  }

  findObjet(id): any | null {
    const object = this.getListObj(this.application).find((f) => (f.id === +id));
    if (object !== undefined) {
      return JSON.parse(JSON.stringify(object));
    } else {
      return null;
    }
  }

  getListObj(application): any[] {
    const list = [];
    for (const champIhm of application.listChampIhm) {
      const champ: ChampIhm = champIhm;
      list.push(champ);
      const list2 = this.getListFromChamp(champIhm);
      for (const item of list2) {
        list.push(item);
      }
    }
    return list;
  }

  getListFromChamp(champIhm: ChampIhm): any[] {
    const list = [];
    for (const champ of champIhm.listChampIhm) {
      list.push(champ);
      const list2 = this.getListFromChamp(champ);
      for (const item of list2) {
        list.push(item);
      }
    }
    for (const zoneIhm of champIhm.listZoneIhm) {
      list.push(zoneIhm);
      for (const champI of zoneIhm.listChampIhm) {
        const champ: ChampIhm = champI;
        list.push(champ);
        const list2 = this.getListFromChamp(champI);
        for (const item of list2) {
          list.push(item);
        }
      }
    }
    return list;
  }

  public getWidth(typewidth, pixelwidth, pourcwidth, calcpourcwidth, calcpixelwidth): string {
    if (typewidth === 0) {
      return `${pixelwidth}px`;
    } else if (typewidth === 1) {
      return `${pourcwidth}%`;
    } else {
      return `calc(${calcpourcwidth}% - ${calcpixelwidth}px)`;
    }
  }

  public getHeight(typeheight, pixelheight, pourcheight, calcpourcheight, calcpixelheight): string {
    if (typeheight === 0) {
      return `${pixelheight}px`;
    } else if (typeheight === 1) {
      return `${pourcheight}%`;
    } else {
      return `calc(${calcpourcheight}% - ${calcpixelheight}px)`;
    }
  }

  getOption(options: any, identifier: string): string {
    let option: any;

    if (undefined === options) {
      return '';
    }

    if (options.listChampIhmOptions) {
      option = options.listChampIhmOptions.find((f) => f.optionIdentifier === identifier);
    } else if (options.listChampEtatOptions) {
      option = options.listChampEtatOptions.find((f) => f.optionIdentifier === identifier);
    } else {
      option = options.find((f) => f.optionIdentifier === identifier);
    }

    if (undefined === option) {
      return '';
    }
    return option.optionValue;
  }

  setOption(options: any, identifier: string, value: string): void {
    let option: any;

    if (undefined === options) {
      return;
    }

    if (options.listChampIhmOptions) {
      option = options.listChampIhmOptions.find((f) => f.optionIdentifier === identifier);
    } else if (options.listChampEtatOptions) {
      option = options.listChampEtatOptions.find((f) => f.optionIdentifier === identifier);
    } else {
      option = options.find((f) => f.optionIdentifier === identifier);
    }

    if (option !== undefined) {
      option.optionValue = value;
    }
  }

  public guid(): string {
    function s4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    }
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  public getElementSize(id): any {
    const element: HTMLElement = document.getElementById(id);
    const data = this.offset(element);
    const headerElement: HTMLElement = document.getElementById('pageHeader');
    const headerData = this.offset(headerElement);
    data.top = data.top - headerData.height + 5;
    return data;
  }

  offset(el: HTMLElement): any {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft, height: rect.height, width: rect.width };
  }
}
