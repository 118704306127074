import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';
import { UtilModule } from '../utils/utils.module';
import { UdoSharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularEditorModule } from '@kolkov/angular-editor';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DndModule,
    UtilModule,
    UdoSharedModule,
    PdfViewerModule,
    NgxChartsModule,
    RouterModule,
    BrowserAnimationsModule,
    AngularEditorModule
  ],
  declarations: [  
    
  ],
  exports: [
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PopupModule { }
